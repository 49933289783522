import React from "react"
import createTemplate from "../../create-template"
import { useDialogState, Dialog, DialogBackdrop } from "reakit/Dialog"

import { useComponents } from "../../components"

function Broadway({ editing, styles, ...props }) {
  const Elements = useComponents()

  const checkboxes_color = styles.forElement("content_text").color
  const svg_color = styles.forElement("video").color

  let svg_style = {}
  let bg_style = styles.forElement("background")
  let bg_color_style = {
    backgroundColor: styles.forElement("background").backgroundColor
  }
  let videoModalStyle = {}

  if (editing) {
    svg_style = { position: "absolute" }
    bg_style["position"] = "absolute"
    bg_color_style["position"] = "absolute"
    videoModalStyle = { position: "absolute" }
  }

  const dialog = useDialogState()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-background" style={bg_color_style} />
      <div className="formkit-background" style={bg_style} />
      <div className="formkit-play" style={svg_style}>
        <button
          id="formkit-play"
          className="formkit-play-button"
          style={{ color: svg_color }}
          onClick={dialog.show}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 125 124"
            fill="none"
          >
            <path
              style={styles.forElement("play_button")}
              fill="currentColor"
              fillRule="evenodd"
              d="M54.2811 42.6093c-2.0808-1.3636-3.7811-.3602-3.7811 2.229v34.3228c0 2.5926 1.7003 3.5925 3.7811 2.2289l28.5418-17.6799s1.0134-.7138 1.0134-1.7138c0-.9932-1.0134-1.707-1.0134-1.707l-28.5418-17.68z"
              clipRule="evenodd"
            />
            <path
              style={styles.forElement("play_button")}
              stroke="currentColor"
              strokeWidth="4"
              d="M62.5 122c33.1371 0 60-26.8629 60-60 0-33.1371-26.8629-60-60-60-33.1371 0-60 26.8629-60 60 0 33.1371 26.8629 60 60 60z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <Elements.Button
          className="formkit-play-text"
          name="play_content"
          type="button"
          onClick={dialog.show}
          defaults={{
            content: "Watch channel trailer"
          }}
        />
        {editing && (
          <button
            onClick={dialog.show}
            type="button"
            style={{
              background: "rgba(255,255,255,0.75)",
              cursor: "pointer",
              display: "block",
              fontSize: 18,
              border: "1px solid #ccc",
              borderRadius: "3px",
              padding: "8px 8px",
              outline: 0,
              width: "200px",
              marginLeft: "-40px",
              marginTop: "15px"
            }}
          >
            Click to edit the video
          </button>
        )}
      </div>
      <div
        className="formkit-container"
        style={styles.forElement("content_background")}
      >
        <Elements.Video
          id="video"
          name="video"
          className="formkit-sm-video"
          defaults={{
            src: "https://youtu.be/8AuMYiw2V-w"
          }}
        />
        <header>
          <Elements.Content
            className="formkit-preheading"
            name="preheader"
            defaults={{
              content: "<p>YouTube Channel</p>"
            }}
          />
          <Elements.Heading
            className="formkit-heading-primary"
            name="header"
            defaults={{
              content:
                "Your go-to guide to becoming a professional music artist"
            }}
          />
        </header>
        <div className="formkit-main">
          <Elements.Region className="formkit-content" name="content">
            <Elements.Content
              defaults={{
                content:
                  "<p>As a self-made musician, I understand the struggles of trying to make it on your own in the music industry. I believe in sharing my story of achieving my goals to help you achieve yours.</p><p>Every week I upload a vlog, tutorial, behind-the-scenes video, Q&amp;A, or music video so that you can follow along on the journey and learn how to become a professional music artist too.</p>"
              }}
            />
          </Elements.Region>
          <Elements.Form>
            <Elements.Errors />
            <Elements.CustomFields
              style={{
                color: checkboxes_color
              }}
            >
              <Elements.AddFieldButton />
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Subscribe today" }}
              />
            </Elements.CustomFields>
          </Elements.Form>
          <Elements.Content
            name="disclaimer"
            defaults={{
              content:
                "<p>We respect your privacy. Unsubscribe at any time.</p>"
            }}
          />
          <Elements.BuiltWith background="content_background" />
        </div>
      </div>
      <DialogBackdrop {...dialog} className="formkit-overlay">
        <div className="formkit-modal-container" style={videoModalStyle}>
          <Dialog
            {...dialog}
            className="formkit-dialog"
            unstable_portal={false}
            {...(editing ? { modal: false, hideOnClickOutside: false } : {})}
          >
            <Elements.Video
              id="video"
              name="video"
              className="formkit-video"
              defaults={{
                src: "https://youtu.be/8AuMYiw2V-w"
              }}
            />
          </Dialog>
          <button className="formkit-modal__close" onClick={dialog.hide}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="times"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                style={styles.forElement("close_button")}
                fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
              />
            </svg>
          </button>
        </div>
      </DialogBackdrop>
    </Elements.LandingPage>
  )
}

export default createTemplate(Broadway, { name: "Broadway" })
